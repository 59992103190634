import { forwardRef } from "react";
import { badgeVariants, type BadgeVariants } from "./badge.styles";

export type BadgeRef = React.ElementRef<"span">;

export interface BadgeProps
  extends React.ComponentPropsWithoutRef<"span">,
    BadgeVariants {}

const Badge = forwardRef<BadgeRef, BadgeProps>(
  ({ className, palette, size, textOnly, variant, ...props }, ref) => {
    return (
      <span
        {...props}
        className={badgeVariants({
          className,
          palette,
          size,
          textOnly,
          variant,
        })}
        ref={ref}
      />
    );
  },
);

Badge.displayName = "Badge";

export { Badge };

import { compose, cva, type VariantProps } from "./style.utils";
import { textInputVariants } from "./text-input.styles";

// -----------------------------------------------------------------------------

export type SearchInputRootVariants = VariantProps<
  typeof searchInputRootVariants
>;

export const searchInputRootVariants = cva({
  base: [
    // layout
    "inline-flex w-full min-w-120px items-center rounded-md",
    // border
    "border",
    // focus-within
    "focus-within:outline focus-within:outline-2 focus-within:outline-ring",
  ],
  variants: {
    invalid: {
      true: "",
      false: "",
    },
    disabled: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      invalid: false,
      disabled: false,
      className: [
        // border
        "border-shade-10",
        // background
        "bg-white",
        // focus-within
        "focus-within:-outline-offset-2",
      ],
    },
    {
      invalid: true,
      disabled: false,
      className: [
        // ring
        "ring-1 ring-error-100",
        // border
        "border-error-100",
        // background
        "bg-error-20",
        // focus-within
        "focus-within:-outline-offset-1",
        // focus-within ring
        "focus-within:ring-ring",
      ],
    },
    {
      invalid: [true, false],
      disabled: true,
      className: [
        // disabled border
        "border-shade-10",
        // disabled background
        "bg-shade-2",
      ],
    },
  ],
  defaultVariants: {
    invalid: false,
    disabled: false,
  },
});

// -----------------------------------------------------------------------------

export type SearchInputControlVariants = VariantProps<
  typeof searchInputControlVariants
>;

export const searchInputControlVariants = compose(
  textInputVariants,
  cva({
    base: [
      // reset variants
      "!border-0 !outline-0 !ring-0",
      // layout
      "min-w-0 flex-1 pl-0 pr-2px",
      // foreground mobile/zoom fix
      "sm:text-sm",
    ],
    variants: {
      size: {
        // 2px less to accomodate root borders
        sm: "h-28px", // compact
        md: "h-34px", // convenient
        lg: "h-38px", // cozy
      },
      invalid: {
        true: "",
        false: "",
      },
      disabled: {
        true: "",
        false: "",
      },
    },
    defaultVariants: {
      size: "md",
      invalid: false,
      disabled: false,
    },
  }),
);

// -----------------------------------------------------------------------------

export type SearchInputPrefixVariants = VariantProps<
  typeof searchInputPrefixVariants
>;

export const searchInputPrefixVariants = cva({
  base: [
    // layout
    "inline-flex items-center rounded-l-md",
    // foreground
    "text-sm font-semibold",
    // animation
    "transition-colors",
    // disabled cursor
    "disabled:cursor-not-allowed",
  ],
  variants: {
    size: {
      sm: "h-full pl-8px pr-3px", // compact
      md: "h-full pl-9px pr-4px", // convenient
      lg: "h-full pl-8px pr-3px", // cozy
    },
    active: {
      true: "",
      false: "",
    },
    invalid: {
      true: "",
      false: "",
    },
    disabled: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      invalid: false,
      disabled: false,
      className: [
        // foreground
        "text-shade-40",
        // focus-within
        "focus-visible:-outline-offset-1",
      ],
    },
    {
      invalid: true,
      disabled: false,
      className: [
        // foreground
        "text-error-120",
        // focus-within
        "focus-visible:outline-offset-0",
      ],
    },
    {
      invalid: [true, false],
      disabled: true,
      className: "text-shade-40/20",
    },
  ],
  defaultVariants: {
    size: "md",
    active: false,
    invalid: false,
    disabled: false,
  },
});

import { forwardRef } from "react";
import {
  SearchInputControl,
  SearchInputPrefix,
  SearchInputRoot,
  type SearchInputControlProps,
  type SearchInputControlRef,
  type SearchInputPrefixProps,
  type SearchInputRootProps,
} from "./search-input.components";
import { formatAriaInvalid } from "./style.utils";

export type SearchInputRef = SearchInputControlRef;

export interface SearchInputProps extends SearchInputControlProps {
  prefixProps?: SearchInputPrefixProps;
  rootProps?: Omit<SearchInputRootProps, "className">;
}

const SearchInput = forwardRef<SearchInputRef, SearchInputProps>(
  (
    {
      "aria-invalid": ariaInvalid,
      className,
      name,
      size,
      disabled,
      prefixProps,
      rootProps,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <SearchInputRoot
        {...rootProps}
        className={className}
        invalid={formatAriaInvalid(ariaInvalid)}
        disabled={disabled}
      >
        <SearchInputPrefix
          {...prefixProps}
          size={size}
          invalid={formatAriaInvalid(ariaInvalid)}
          disabled={disabled}
        />
        <SearchInputControl
          {...inputProps}
          name={name}
          size={size}
          disabled={disabled}
          autoComplete="off"
          spellCheck={false}
          aria-invalid={ariaInvalid}
          ref={ref}
        />
      </SearchInputRoot>
    );
  },
);

SearchInput.displayName = "SearchInput";

export { SearchInput };

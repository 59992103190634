import { cva, type VariantProps } from "./style.utils";

export type BadgeVariants = VariantProps<typeof badgeVariants>;

export const badgeVariants = cva({
  base: [
    // layout
    "inline-flex items-center align-middle",
    // radii/shadow
    "rounded-full",
    // foreground
    "text-xs leading-none",
  ],
  variants: {
    variant: {
      solid: "",
      subtle: "",
    },
    palette: {
      info: "",
      error: "",
      warning: "",
      success: "",
      neutral: "",
      secondary: "",
    },
    size: {
      sm: "h-20px min-w-20px px-8px",
      md: "h-24px min-w-24px px-10px",
    },
    textOnly: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      size: "sm",
      variant: "subtle",
      textOnly: false,
      className: "gap-4px pr-9px before:size-8px",
    },
    {
      size: "md",
      variant: "subtle",
      textOnly: false,
      className: "gap-4px pr-11px before:size-10px",
    },

    {
      variant: "solid",
      palette: ["info", "error", "warning", "success", "neutral", "secondary"],
      className: "text-white",
    },
    {
      variant: "subtle",
      palette: ["info", "error", "warning", "success", "neutral", "secondary"],
      className: "text-shade-100",
    },

    {
      variant: "solid",
      palette: "info",
      className: "bg-info-100",
    },
    {
      variant: "solid",
      palette: "error",
      className: "bg-error-100",
    },
    {
      variant: "solid",
      palette: "warning",
      className: "bg-warning-100",
    },
    {
      variant: "solid",
      palette: "success",
      className: "bg-success-100",
    },
    {
      variant: "solid",
      palette: "neutral",
      className: "bg-neutral-100",
    },
    {
      variant: "solid",
      palette: "secondary",
      className: "bg-secondary-100",
    },

    {
      variant: "subtle",
      palette: "info",
      className: "bg-info-20",
    },
    {
      variant: "subtle",
      palette: "error",
      className: "bg-error-20",
    },
    {
      variant: "subtle",
      palette: "warning",
      className: "bg-warning-20",
    },
    {
      variant: "subtle",
      palette: "success",
      className: "bg-success-20",
    },
    {
      variant: "subtle",
      palette: "neutral",
      className: "bg-neutral-20",
    },
    {
      variant: "subtle",
      palette: "secondary",
      className: "bg-secondary-20",
    },

    {
      variant: "subtle",
      textOnly: false,
      className: "before:rounded-full",
    },
    {
      variant: "subtle",
      textOnly: false,
      palette: "info",
      className: "before:bg-info-100",
    },
    {
      variant: "subtle",
      textOnly: false,
      palette: "error",
      className: "before:bg-error-100",
    },
    {
      variant: "subtle",
      textOnly: false,
      palette: "warning",
      className: "before:bg-warning-100",
    },
    {
      variant: "subtle",
      textOnly: false,
      palette: "success",
      className: "before:bg-success-100",
    },
    {
      variant: "subtle",
      textOnly: false,
      palette: "neutral",
      className: "before:bg-neutral-120",
    },
    {
      variant: "subtle",
      textOnly: false,
      palette: "secondary",
      className: "before:bg-secondary-120",
    },
  ],
  defaultVariants: {
    variant: "subtle",
    palette: "neutral",
    size: "md",
    textOnly: false,
  },
});

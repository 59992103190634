import { forwardRef } from "react";
import { Icon } from "./icon";
import {
  searchInputControlVariants,
  searchInputPrefixVariants,
  searchInputRootVariants,
  type SearchInputControlVariants,
  type SearchInputPrefixVariants,
  type SearchInputRootVariants,
} from "./search-input.styles";
import { formatAriaInvalid } from "./style.utils";

// -----------------------------------------------------------------------------

export type SearchInputRootRef = React.ElementRef<"span">;

export interface SearchInputRootProps
  extends React.ComponentPropsWithoutRef<"span">,
    SearchInputRootVariants {}

const SearchInputRoot = forwardRef<SearchInputRootRef, SearchInputRootProps>(
  ({ className, disabled, invalid, ...props }, ref) => {
    return (
      <span
        {...props}
        className={searchInputRootVariants({ className, disabled, invalid })}
        ref={ref}
      />
    );
  },
);

SearchInputRoot.displayName = "SearchInputRoot";

export { SearchInputRoot };

// -----------------------------------------------------------------------------

export type SearchInputPrefixRef = React.ElementRef<"span">;

export interface SearchInputPrefixProps
  extends Omit<React.ComponentPropsWithoutRef<"span">, "children">,
    SearchInputPrefixVariants {}

const SearchInputPrefix = forwardRef<
  SearchInputPrefixRef,
  SearchInputPrefixProps
>(({ className, disabled, invalid, size, ...props }, ref) => {
  return (
    <span
      {...props}
      className={searchInputPrefixVariants({
        className,
        disabled,
        invalid,
        size,
      })}
      ref={ref}
    >
      <Icon name="ms-search" size={size} />
    </span>
  );
});

SearchInputPrefix.displayName = "SearchInputPrefix";

export { SearchInputPrefix };

// -----------------------------------------------------------------------------

export type SearchInputControlRef = React.ElementRef<"input">;

export interface SearchInputControlProps
  extends Omit<React.ComponentPropsWithoutRef<"input">, "size">,
    Omit<SearchInputControlVariants, "invalid"> {
  name: string;
  type?: "search" | "text";
}

const SearchInputControl = forwardRef<
  SearchInputControlRef,
  SearchInputControlProps
>(
  (
    {
      "aria-invalid": ariaInvalid,
      className,
      disabled,
      size,
      type = "text",
      ...props
    },
    ref,
  ) => {
    return (
      <input
        {...props}
        type={type}
        disabled={disabled}
        aria-invalid={ariaInvalid}
        className={searchInputControlVariants({
          className,
          disabled,
          invalid: formatAriaInvalid(ariaInvalid),
          size,
        })}
        ref={ref}
      />
    );
  },
);

SearchInputControl.displayName = "SearchInputControl";

export { SearchInputControl };

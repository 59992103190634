import { cva, type VariantProps } from "./style.utils";

export type TextInputVariants = VariantProps<typeof textInputVariants>;

export const textInputVariants = cva({
  base: [
    // layout
    "w-full min-w-120px rounded-md",
    // border
    "border border-shade-10",
    // background
    "bg-white",
    // foreground
    "text-shade-100",
    // foreground mobile/zoom fix
    "sm:text-sm",
    // placeholder
    "placeholder:text-shade-100/50",
    // focus outline/border
    "focus:outline-1 focus:outline-offset-0",
    "focus:border-ring focus:outline-ring",
  ],
  variants: {
    size: {
      sm: "h-30px px-10px", // compact
      md: "h-36px px-12px", // convenient
      lg: "h-40px px-14px", // cozy
    },
    invalid: {
      true: "",
      grammar: "",
      spelling: "",
      false: "",
    },
    disabled: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      invalid: [true, "grammar", "spelling"],
      disabled: false,
      className: [
        // invalid ring
        "ring-1 ring-error-100",
        // invalid border
        "border-error-100",
        // invalid background
        "bg-error-20",
        // invalid focus outline/border
        "focus:border-ring focus:outline-ring",
      ],
    },
    {
      disabled: true,
      className: [
        // disabled
        "cursor-not-allowed",
        // disabled border
        "border-shade-10",
        "invalid:border-shade-10",
        // disabled background
        "bg-shade-2",
        "invalid:bg-shade-2",
        // disabled foreground
        "text-shade-50",
        "invalid:text-shade-50",
        // disabled placeholder
        "placeholder:text-shade-50/50",
        "invalid:placeholder:text-shade-50/50",
      ],
    },
  ],
  defaultVariants: {
    size: "md",
    invalid: false,
    disabled: false,
  },
});
